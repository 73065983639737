import React, { createContext, useState, useContext } from 'react';

var environment = process.env.NODE_ENV || 'development'
var requestUrl = null

if (environment !== "production") {
    requestUrl = "http://localhost:4000"
} else {
    requestUrl = "https://admin.fittir.io"
}

const AppContext = createContext();

export const AppProvider = ( { children }) => {

    const [sessionUser, setSessionUser] = useState({})

    const [isAlert, setIsAlert] = useState({
        error: false,
        success: false
    })

    const [upgradeModalOpen, setUpgradeModalOpen] = useState(false)

    const setAlertState = ( state ) => {
        setIsAlert(isAlert => {
            return {
                ...isAlert,
                error: state,
                success: state
            }
        })
    }

    const [disabledSubmit, setDisabledSubmit] = useState(false)

    const [isLoading, setIsLoading] = useState(false)
    const setLoading = ( state ) => {
        setIsLoading(state)
    }

    const [isFiltering, setIsFiltering] = useState(false)

    const [isProcessing, setIsProcessing] = useState(false)
    const setProcessing = ( state ) => {
        setIsProcessing(state)
    }

    var [partner, setPartner] = useState()


    const value = {
        isAlert,
        setIsAlert,
        setAlertState,
        isLoading,
        setLoading,
        isProcessing,
        setProcessing, 
        disabledSubmit,
        setDisabledSubmit,
        requestUrl,
        isFiltering,
        setIsFiltering,
        environment,
        partner, 
        setPartner,
        sessionUser,
        setSessionUser,
        upgradeModalOpen,
        setUpgradeModalOpen
    }



    return <AppContext.Provider value={value}> {children} </AppContext.Provider>
}

export const useAppContext = () => useContext(AppContext);
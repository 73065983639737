import { useState, useEffect } from "react"
import { Button } from "@mui/material"
import { useNavigate, useLocation } from "react-router-dom"
import { useAppContext } from "../../../../utils/ContextProvider.jsx/index"
import axios from "axios"
import DashboardHeader from "../../../../components/Dashboard/Header/index.jsx"
import TextField from "@mui/material/TextField"
import CircularProgress from "@mui/material/CircularProgress"
import Autocomplete from "@mui/material/Autocomplete"
import { runningProfileOptions } from "../../../../utils/ProfileOptions"

export default function MapShoePage () {

    var navigate = useNavigate()

    var { mapping, unprocessedMappings, allShoes } = useLocation().state || {}

    var [ mapping, setMapping ] = useState(mapping)

    var [ width, setWidth ] = useState()

    var [ shoe, setShoe ] = useState()

    var [ colorways, setColorways ] = useState([])

    var [ shoeColorway, setShoeColorway ] = useState()
    

    const { isLoading, isAlert, setAlertState, setIsLoading, requestUrl, setIsAlert, setLoading, isProcessing, setProcessing } = useAppContext()

    const handleSubmit = (evt) => {
        if (width && shoe && shoeColorway) {
            var url = `${requestUrl}/api/partnerDeals/${mapping._id}`
            setProcessing(true)
            axios({
                url: url,
                method: 'PUT',
                withCredentials: true,
                data: {
                    width: width,
                    shoe: shoe,
                    shoeColorway: shoeColorway,
                    processed: true,
                    validDeal: true
                }
            })
            .then((res) => {
                var index = unprocessedMappings.indexOf(mapping);
                if (index !== -1) {
                    var updatedUnprocessedMappings = unprocessedMappings.slice(index + 1);
                    var nextMapping = updatedUnprocessedMappings[0];
                }
                setMapping(nextMapping);
                setWidth();
                setShoe();
                setShoeColorway();   
                setProcessing(false) 
                if (nextMapping) {
                    navigate(`/admin/partners/mappings/${nextMapping._id}`, {state: { mapping: nextMapping , unprocessedMappings: updatedUnprocessedMappings, allShoes: allShoes }})
                } else {
                    navigate(`/admin/partners/mappings`)
                }
            })
            .catch((err) => {
                console.log(err)
            })
        }
        
        
    }

    const handleSkip = () => {
        var index = unprocessedMappings.indexOf(mapping);
        if (index !== -1) {
            var nextMapping = unprocessedMappings[index + 1];
            setMapping(nextMapping)
        }
        if (nextMapping) {
            navigate(`/admin/partners/mappings/${nextMapping._id}`, {state: { mapping: nextMapping , unprocessedMappings: unprocessedMappings, allShoes: allShoes }});
        } else {
            navigate(`/admin/partners/mappings`);
        }
    }
    
    const handleDelete = (evt) => {
        var url = `${requestUrl}/api/partnerDeals/${mapping._id}`
        setProcessing(true)
        axios({
            url: url,
            method: 'PUT',
            withCredentials: true,
            data: {
                width: null,
                shoe: null,
                shoeColorway: null,
                processed: true,
                validDeal: false
            }
        })
        .then((res) => {
            var index = unprocessedMappings.indexOf(mapping);
                if (index !== -1) {
                    var updatedUnprocessedMappings = unprocessedMappings.slice(index + 1);
                    var nextMapping = updatedUnprocessedMappings[0];
                }
                setMapping(nextMapping);
            setWidth()
            setShoe()
            setShoeColorway()    
            setProcessing(false)
            if (nextMapping) {
                navigate(`/admin/partners/mappings/${nextMapping._id}`, {state: { mapping: nextMapping , unprocessedMappings: updatedUnprocessedMappings, allShoes: allShoes }})
            } else {
                navigate(`/admin/partners/mappings`)
            }
        })
        .catch((err) => {
            console.log(err)
        })
    }

   

    const handleShoeChange = (evt, value) => {
        setShoe(value)
        if (value) {
            var colorways = value.colorways.filter((colorway) => colorway.gender == value.gender)
            setColorways(colorways)
        }

    }

    const handleColorwayChange = (evt, value) => {
        setShoeColorway(value)
        if (!value) {
            setShoe(value)
        }
    }

    const handleWidthChange = (evt, value) => {
        setWidth(value)
    }

    return (
        <div>
            <DashboardHeader
                    heading={`Mapping: ${mapping?.urlKey}`}
                    subHeading={`Map this partner deal to a shoe for it to show in Fitir`}
                    breadcrumb={[
                        {
                            title: 'Mappings',
                            href: '/admin/partners/mappings'
                        },
                        {
                            title: 'Process'
                        }
                    
                    ]}
                />
                <div className="container-fluid dashboard-body pt-5 pb-5" id="recommendationsArea">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="row">
                            <div className="col">
                                <a href={mapping?.affiliateLink || mapping?.partner.baseUrl + mapping?.urlKey} target="_blank">{mapping?.affiliateLink || mapping?.partner.baseUrl + mapping?.urlKey}</a>
                            </div>
                        </div>
                        <div className="row  mt-3">
                            <div className="col text-center">
                                <img className="img img-fluid" src={`https://fittir-assets.s3.ap-southeast-2.amazonaws.com/partners/${mapping?.partner.slug}.svg`} alt="" />
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col text-center">
                                <img style={{width: '100%'}} className="img img-fluid" key={`${shoe?._id}${shoeColorway?.colorwayName}Image`} src={`https://fittir-shoe-images.s3.ap-southeast-2.amazonaws.com/${shoeColorway?.imageSlug}.png`} alt="" />
                            </div>
                        </div>
                        
                        
                    </div>
                    <div className="col px-4 py-4" style={{backgroundColor: 'white', border: '1px solid white', borderRadius: '0.5em'}}>
                        <div className="row">
                            <div className="col">
                                <h2>Mapping information</h2>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-12 mx-auto mx-md-0">
                            <Autocomplete
                                id="country-select-demo"
                                key={`shoeSelector${shoe?._id}Selected`}
                                options={allShoes}
                                value={shoe}
                                autoHighlight
                                getOptionLabel={(shoe) => `${shoe.brand?.name} ${shoe.model} ${shoe.version} (${shoe.gender})`}
                                onChange={(evt, value) => handleShoeChange(evt, value)}
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    label="Select a shoe"
                                    autoComplete="false"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'off', // disable autocomplete and autofill
                                    }}
                                    />
                                )}
                                />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-12 mx-auto mx-md-0">
                            <Autocomplete
                                id="country-select-demo"
                                key={`${shoe?._id}ColorwaySelector`}
                                options={colorways}
                                value={shoeColorway}
                                autoHighlight
                                getOptionLabel={(colorway) => `${colorway.colorwayName}`}
                                onChange={(evt, value) => handleColorwayChange(evt, value)}
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    label="Select a colorway"
                                    autoComplete="false"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'off', // disable autocomplete and autofill
                                    }}
                                    />
                                )}
                                />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-12 mx-auto mx-md-0">
                            <Autocomplete
                                id="country-select-demo"
                                key={`${shoeColorway?._id}WidthOptionSelector`}
                                options={shoeColorway?.widthOptions}
                                value={width}
                                autoHighlight
                                onChange={(evt, value) => handleWidthChange(evt, value)}
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    label="Select a width"
                                    autoComplete="false"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'off', // disable autocomplete and autofill
                                    }}
                                    />
                                )}
                                />
                            </div>
                        </div>
                        <div className="row mt-4 justify-content-end">
                            <div className="col-auto px-2">
                                <Button
                                variant="link"
                                disabled={isProcessing ? true : false}
                                endIcon= {isProcessing ? <CircularProgress size={24} sx={{ color: 'white' }}/> : null}
                                onClick={handleSkip}
                                >Skip</Button>
                            </div>
                            <div className="col-auto px-2">     
                                <Button
                                onClick={handleDelete}
                                disabled={isProcessing ? true : false}
                                endIcon= {isProcessing ? <CircularProgress size={24} sx={{ color: 'white' }}/> : null}
                                variant="outlined"
                                >Delete</Button>
                            </div>
                            <div className="col-auto px-2">
                                <Button
                                variant="contained"
                                disabled={isProcessing ? true : false}
                                endIcon= {isProcessing ? <CircularProgress size={24} sx={{ color: 'white' }}/> : null}
                                onClick={handleSubmit}
                                >Update</Button>
                            </div>
                            
                        </div>
                    </div>
                </div>

            </div>
        </div>
        </div>

        
 
    )
}

import { useNavigate, ScrollRestoration, Outlet } from "react-router-dom"
import { useAppContext } from "../../utils/ContextProvider.jsx"
import { useEffect, useState } from "react"
import axios from "axios"
import NavBar from "../../components/NavBar/index.jsx"
import Footer from "../../components/Footer/index.jsx"
import FlashAlert from "../../components/Alert/index.jsx"

export default function AdminLayout () {

    var navigate = useNavigate()


    const { isLoading, isAlert, setAlertState, setIsLoading, requestUrl, setIsAlert, setLoading } = useAppContext()

    var [user, setUser] = useState()


    useEffect(()=> {
        var url = `${requestUrl}/api/users`
        axios({
            url: url,
            method: "GET",
            withCredentials: true
        })
        .then((res) => {
            if (res.data.user == null || res.data.user.userGroup !== 'admin' ) {
                setIsAlert(pageData => {
                    return {
                        ...pageData,
                        error: "You are not authorised to view this page"
                    }
                })
                navigate('/')
            } else {
                setUser(res.data.user)
            }
        })
        .catch((err)=> {
        var errorMessage = err.response?.data.err.message || "Whoops, something wen't wrong"
            setIsAlert(pageData => {
                return {
                    ...pageData,
                    error: errorMessage
                }
            })
        })
    },[])

    return (
        <div className="d-flex flex-column px-0 py-0 min-vh-100" style={{backgroundColor: '#F8F9FA'}}>
            {/* <NavBar /> */}
                {isAlert.error ? <FlashAlert severity={"error"} message={isAlert.error} setAlertState={setAlertState} />: null}
                {isAlert.success ? <FlashAlert severity={"success"} message={isAlert.success} setAlertState={setAlertState} />: null}
                <Outlet context={[user]}/>
            {/* <Footer /> */}
            <ScrollRestoration/>
        </div>
    )
}
import { Outlet } from "react-router-dom";
import './index.css'

export default function AuthPage() {
    return (
        <div className="no-nav-or-footer">
            <div className="container-md align-self-center my-3 pt-5">
                <div className="row">
                    <div className="col-11 col-md-10 col-xl-8 mx-auto form-container mb-5">
                        <Outlet />  
                    </div>
                </div>
            </div>
        </div>
        
    )
}